//
// Fonts
//

$open-sans-font-path: '~@cigna/cigna_digital_dcws_cigna-frontend/src/fonts/open-sans/';
$merriweather-font-path: '~@cigna/cigna_digital_dcws_cigna-frontend/src/fonts/merriweather/';

$value-sans-font-path: '~@cigna/cigna_digital_dcws_cigna-frontend/src/fonts/value-sans/';
$value-serif-font-path: '~@cigna/cigna_digital_dcws_cigna-frontend/src/fonts/value-serif/';

$co-brand-header-max-widths: () !default;

$co-brand-header-max-widths: (
  lg: 960px,
  xl: 1140px
);
